import React from "react";
import PasswordMask from "react-password-mask";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Trans from "../../../../components/Translate";
import { Formik, Field } from "formik";
import { useMutation, gql } from "@apollo/client";
import FormResponse from "../../../../components/FormResponse";
import * as Yup from "yup";

const UPDATE_PASSWORD = gql`
  mutation UpdateUserPassword(
    $userPasswordUpdateInput: UserPasswordUpdateInput!
  ) {
    updateUserPassword(data: $userPasswordUpdateInput) {
      user {
        id
      }
    }
  }
`;

const ChangePasswordForm = () => {
  const [updatePassword, { data, error: mutationError, loading }] = useMutation(
    UPDATE_PASSWORD
  );
  const { t } = useTranslation();
  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string()
      .required(t("Password is required"))
      .min(8, t("Password is too short. It should be at least 8 characters."))
      .matches(
        /^(?=.*[A-Z])(?=.*[0-9])/,
        "Must contain one uppercase letter, and one number"
      ),
    newPassword: Yup.string()
      .required(t("Password is required"))
      .min(8, t("Password is too short. It should be at least 8 characters."))
      .matches(
        /^(?=.*[A-Z])(?=.*[0-9])/,
        "Must contain one uppercase letter, and one number"
      ),
    newPasswordConfirm: Yup.string()
      .required(t("Password is required"))
      .min(8, t("Password is too short. It should be at least 8 characters."))
      .test("passwords-match", "Passwords must match", function (value) {
        return this.parent.newPassword === value;
      })
      .matches(
        /^(?=.*[A-Z])(?=.*[0-9])/,
        "Must contain one uppercase letter, and one number"
      ),
  });
  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        currentPassword: "",
        newPassword: "",
        newPasswordConfirm: "",
      }}
      onSubmit={async (
        values,
        { setSubmitting, setStatus, setErrors, resetForm }
      ) => {
        try {
          const result = await updatePassword({
            variables: { userPasswordUpdateInput: values },
          });

          setSubmitting(false);
          setStatus({ success: true });
        } catch (err) {
          const gqlError = { err };
          console.log("gqlError", gqlError);

          setSubmitting(false);
          setStatus({
            error: gqlError?.err?.message,
            messageCode: gqlError?.err?.graphQLErrors[0]?.data?.messageCode,
          });
        }
      }}
    >
      {(formikProps) => {
        const {
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          submitCount,
          status,
          setErrors,
          setStatus,
        } = formikProps;
        return (
          <form id="change-password-form" className="" onSubmit={handleSubmit}>
            <div>
              <div className="form-group">
                <label htmlFor="currentPassword">
                  <Trans i18nKey={"CURRENT_PASSWORD"} />
                </label>
                <PasswordMask
                  //   readOnly={isAdminUpdating}
                  //   required
                  maxLength={50}
                  type="password"
                  inputClassName="form-control rounded"
                  name="currentPassword"
                  id="currentPassword"
                  placeholder=""
                  value={values?.currentPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  buttonStyles={{ right: "4px", marginTop: "-16px" }}
                />

                <div className="small text-danger mt-1">
                  {touched.currentPassword &&
                    errors.currentPassword &&
                    errors.currentPassword}
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="newPassword">
                  <Trans i18nKey={"NEW_PASSWORD"} />
                </label>
                <PasswordMask
                  //   readOnly={isAdminUpdating}
                  //   required
                  maxLength={50}
                  type="password"
                  inputClassName="form-control rounded"
                  name="newPassword"
                  id="newPassword"
                  placeholder=""
                  value={values.newPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  buttonStyles={{ right: "4px", marginTop: "-16px" }}
                />

                <div className="small text-danger mt-1">
                  {touched.newPassword &&
                    errors.newPassword &&
                    errors.newPassword}
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="newPasswordConfirm">
                  <Trans i18nKey={"CONFIRM_NEW_PASSWORD"} />
                </label>
                <PasswordMask
                  //   readOnly={isAdminUpdating}
                  //   required
                  maxLength={50}
                  type="password"
                  inputClassName="form-control rounded"
                  name="newPasswordConfirm"
                  id="newPasswordConfirm"
                  placeholder=""
                  value={values.newPasswordConfirm}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  buttonStyles={{ right: "4px", marginTop: "-16px" }}
                />

                <div className="small text-danger mt-1">
                  {touched.newPasswordConfirm &&
                    errors.newPasswordConfirm &&
                    errors.newPasswordConfirm}
                </div>
              </div>
            </div>
            <FormResponse
              errors={status?.error}
              messageCode={status?.messageCode}
              alert={Object.keys(errors || {}).length > 0 && submitCount > 0}
              isVisible={
                status?.error ||
                status?.success ||
                (Object.keys(errors || {}).length > 0 && submitCount > 0)
              }
            >
              <Trans i18nKey={"PASSWORD_UPDATED"} />
            </FormResponse>
            <button
              id="submit-button"
              type="submit"
              disabled={isSubmitting}
              className="btn btn-secondary font-weight-bold"
            >
              {!loading && (
                <span>
                  <Trans i18nKey={"SUBMIT"} />
                </span>
              )}
              {loading && (
                <span
                  className="spinner-border spinner-border-sm"
                  role="formStatus"
                  aria-hidden="true"
                />
              )}
            </button>
          </form>
        );
      }}
    </Formik>
  );
};

export default ChangePasswordForm;
